import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalSignatureComponent } from './digital-signature.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { ThankYouComponent } from './thank-you/thank-you.component'; // Percorso corretto del componente
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { ThankYouModule } from './thank-you/thank-you.module';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';


@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    ThankYouModule,
    RouterModule.forChild([
      {
        path: "",
        component : DigitalSignatureComponent
      },
      {
        path: "thank-you",
        component: ThankYouComponent
      },
      {
        path: "error-message",
        component: ErrorMessageComponent
      }
  ]),
  ],
  declarations: [
    DigitalSignatureComponent
  ],
  exports: [DigitalSignatureComponent]
})
export class DigitalSignatureModule { }
